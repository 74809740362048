import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ src, alt, imgStyle, style, className }) => {
  const data = useStaticQuery(graphql`
    query ImagesQuery {
      images: allS3Object {
        nodes {
          Key
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 1200
                quality: 75
              )
            }
          }
        }
      }
    }
  `);

  // filter images until we get variables in static queries
  // https://github.com/gatsbyjs/gatsby/issues/10482
  const image = data.images.nodes.find((image) => image.Key === src);

  return (
    <GatsbyImage
      image={image.localFile.childImageSharp.gatsbyImageData}
      alt={alt ? alt : ""}
      imgStyle={imgStyle ? imgStyle : ""}
      style={style ? style : ""}
      className={className ? className : ""}
    />
  );
};

export default Image;
