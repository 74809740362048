import React from 'react'
import { MDXProvider } from '@mdx-js/react'
// import Markdown from 'markdown-to-jsx';

import Image from './image'
import { Code } from './code'

const getExtension = str => str.slice(str.lastIndexOf("."));

// const MyImage = props => { 
//   // console.log(props)
//   return (
//     <Image {...props} />
//   )
// }

const components = {
  // h1: ({ children }) => (
  //   <h1 style={{ fontSize: '4em', color: 'red' }}>{children}</h1>
  // ),
  // h2: ({ children }) => (
  //   <h2 style={{ color: 'rebeccapurple' }}>{children}</h2>
  // ),
  // h3: ({ children }) => (
  //   <h3 style={{ color: 'rebeccapurple' }}>{children}</h3>
  // ),
  p: ({children, ...rest}) => {
    if (children.props && children.props.mdxType === "img") {
      return (
        <>{children}</>
      )
    } else {
      return (
        <p {...rest}>{children}</p>
      )
    }
  },
  // img: MyImage,
  // img: (props) => <Image {...props} />,

  img: (props) => {
    // console.log(props.src)
    // console.log(getExtension(props.src))

    if (getExtension(props.src) === ".svg") {
      return (
        <img {...props} src={props.src} alt="" />
      )
    } else if (getExtension(props.src) === ".gif") {
      return (
        <img {...props} src={props.src} alt="" />
      )
    } else {
      return (
        <Image {...props} />
      )
    }
  },

  'p.inlineCode': props => (
    <code style={{ backgroundColor: 'lightgray' }} {...props} />
  ),
  pre: ({ children: { props } }) => {
    if (props.mdxType === 'code') {
      return (
        <Code
          codeString={props.children.trim()}
          language={
            props.className && props.className.replace('language-', '')
          }
          {...props}
        />
      );
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);