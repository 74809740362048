import React from 'react';

// Page components -----------------------------------------------------------
import { Highlight, themes } from 'prism-react-renderer';
import { LiveEditor, LiveError, LivePreview, LiveProvider} from 'react-live';
import { copyToClipboard } from '../utils/copy-to-clipboard';

// Page styles ---------------------------------------------------------------
import * as code from '../../styles/components/modules/code.module.scss';

export const Code = ({ codeString, language, ...props }) => {
  if (props['react-live']) {
    return (
      <LiveProvider
        code={codeString}
        noInline={true}
        theme={themes.nightOwl}
      >
        <LiveEditor />
        <LiveError />
        <LivePreview />
      </LiveProvider>
    );
  }

  const handleClick = () => {
    copyToClipboard(codeString);
  };

  return (
    <Highlight
      code={codeString}
      language={language}
      theme={themes.nightOwl}
    >
      {({
        style,
        tokens,
        getLineProps,
        getTokenProps,
      }) => (
        <pre className={code.pre} style={style}>
          <button className={code.copy_code} onClick={handleClick}>Copy</button>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              <div className={code.line_number}>{i + 1}</div>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
